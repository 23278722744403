import { useState, useEffect } from "react";

import "./file_upload_cell.scss";

export default function FileUploadCell({ value: initialValue, row: { index }, column: { id }, updateMyData }) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.files[0].name);
  }

  const onBlur = () => {
    updateMyData(index, id, value);
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  // let inputMarkup;
  // if (!value) {
  //   inputMarkup = <input type="file" className="table-file-input" onChange={onChange} onBlur={onBlur} />
  // }
  // else {
  //   inputMarkup = <span>{value}</span>
  // }

  return (
    <div className="table-file-container">
      {/* <i className="fas fa-edit"></i> */}
      <input type="file" className="table-file-input" onChange={onChange} onBlur={onBlur} />
      {/* {inputMarkup} */}
    </div>
  )
}
