import { Link } from "react-router-dom";

import "./navigation_item.scss";

export default function NavigationItem(props) {
  let iconDisplay;
  if (props.updates && props.updates > 0) {
    iconDisplay = <span class="badge rounded-pill bg-danger text-light">{props.updates}</span>
  }
  else {
    iconDisplay = <i class={`fas ${props.icon}`}></i>
  }

  return (
    <Link to={`${props.href}`}>
      <div className={`aim-nav-link clickable`}>
        {iconDisplay}
        <span className="aim-nav-link-label">{props.text}</span>
      </div>
    </Link>
  );
}
