import NavigationItem from "./NavigationItem/NavigationItem";

import "./navbar.scss";

export default function Navbar(props) {
  function logout() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('activeProject');
    localStorage.removeItem('last_name');
    localStorage.removeItem('role');
    localStorage.removeItem('first_name');
    localStorage.removeItem('username');
    localStorage.removeItem('updates');

    window.location.replace('/login');
  }

  let projectNav;
  if (props.activeProject && !['/projects', '/projects/dataadmin', '/projects/support'].includes(window.location.pathname)) {
    let supportMarkup;
    if (localStorage.getItem('role') !== 'admin') {
      supportMarkup = (
        <>
          {/* <NavigationItem text="Help" icon="fa-question-circle" href={`/projects/${props.activeProject.id}/support`} /> */}
        </>
      );
    }
      
    let projectLabelMarkup;
    if (localStorage.getItem('role') === 'admin') {
      projectLabelMarkup = (
        <div className="project-nav-header">
          <i class="fas fa-dice-d6"></i>
          <p>{props.activeProject.name}</p>
        </div>
      );
    }

    projectNav = (
      <>
        {projectLabelMarkup}
        <NavigationItem text="Dashboard" icon="fa-chart-line" href={`/projects/${props.activeProject.id}`} />
        <NavigationItem text="Import Data" icon="fa-file-import" href={`/projects/${props.activeProject.id}/import`} />
        {/* <NavigationItem text="Upload History" icon="fa-upload" href={`/projects/${props.activeProject.id}/history`} /> */}
        <NavigationItem text="Contacts" icon="fa-edit" href={`/projects/${props.activeProject.id}/editcontacts`} updates={props.contactUpdates} />
        <NavigationItem text="Financial Accounts" icon="fa-edit" href={`/projects/${props.activeProject.id}/editagreements`} updates={props.agreementUpdates} />
        {supportMarkup}
      </>
    )
  }

  let navMarkup;
  if (localStorage.getItem('role') === 'admin') {
    navMarkup = (
      <>
        <NavigationItem text="Home" icon="fa-home" href="/projects" />
        <div id="admin-project-nav">
          {projectNav}
        </div>
        <NavigationItem text="Global Data Admin" icon="fa-wrench" href="/projects/dataadmin" />
        {/* <NavigationItem text="Help" icon="fa-question-circle" href="/projects/support" /> */}
      </>
    );
  }
  else {
    navMarkup = projectNav;
  }

  return (
    <nav id="left-nav">
      <div id="corner-logo">
        <h1 className="aim-logo">AIM</h1>
        <span className="aim-logo-subtext">Advisor Intake Manager</span>
      </div>
      <div id="nav-link-container">
        {navMarkup}
        <hr />
        {/* <NavigationItem text="Sign Out" icon="fa-sign-out-alt" href="/projects/support" /> */}
        <div className={`aim-nav-link clickable`} onClick={logout}>
          <i className="fas fa-sign-out-alt"></i>
          <span className="aim-nav-link-label">Sign Out</span>
        </div>
      </div>
      <div id="powered-by-ht-logo-container">
        <img id="powered-by-ht-logo" src="/images/PoweredByHT.png" alt="aim logo"/>
      </div>
    </nav>
  )
}
