export default function Setup() {
  // read query string parameters and shove them into local storage
  const urlParams = new URLSearchParams(window.location.search);

  localStorage.setItem('username', urlParams.get('username'));
  localStorage.setItem('role', urlParams.get('role'));
  localStorage.setItem('first_name', urlParams.get('first_name'));
  localStorage.setItem('last_name', urlParams.get('last_name'));
  localStorage.setItem('user_id', urlParams.get('user_id'));

  if (urlParams.get('role') === 'admin') {
    window.location.replace('/projects');
  }
  else if (urlParams.get('role') === 'reviewer') {
    window.location.replace('/reviewer');
  }
  else {
    localStorage.setItem('activeProject', JSON.stringify({"id":"407db3d1-f03e-41b9-9209-226e2a4c9a54","name":"Project Cameron - Iverson Capital","created_at":1609948808000,"updated_at":2,"status":"Complete","advisors":["79bae14a-b40f-4964-b93b-0049f8f670b7","42846a68-2919-4467-bea7-3cae736647e7"],"ht_advisors":["d6667c39-fd76-4623-a56b-8a7217c3fc1a","9d8acec9-29bd-4b08-b2ae-65c6d9bdf591"],"record_count":{"red":33,"yellow":63,"green":5,"total":101}}))
    window.location.replace('/projects/407db3d1-f03e-41b9-9209-226e2a4c9a54');
  }

  return (
    <div>
      Loading
    </div>
  );
}
