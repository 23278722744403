import { useState, useEffect } from "react";

import "./number_cell.scss";

export default function NumberCell({ value: initialValue, row: { index }, column: { id }, updateMyData }) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  }

  const onBlur = () => {
    updateMyData(index, id, value);
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  return (
    <div className="table-number-container">
      {/* <i className="fas fa-edit"></i> */}
      <input type="number" className="table-number-input" value={value} onChange={onChange} onBlur={onBlur} />
    </div>
  )
}
