import { useMemo } from "react";
import { useTable } from 'react-table';

import "./report_table.scss";
 
export default function ContactsReportTable(props) {
  const data = useMemo(() => props.data, [props.data])

  const columns = useMemo(
    () => [
      {
        Header: "Anniversary",
        accessor: "Anniversary"
      },
      {
        Header: "Cell Phone #",
        accessor: "Cell Phone #"
      },
      {
        Header: "Date of Birth",
        accessor: "Date of Birth"
      },
      {
        Header: "Govenrment ID Type",
        accessor: "Govenrment ID Type"
      },
      {
        Header: "Government ID Number",
        accessor: "Government ID Number"
      },
      {
        Header: "Home City",
        accessor: "Home City"
      },
      {
        Header: "Home Country",
        accessor: "Home Country"
      },
      {
        Header: "Home Phone #",
        accessor: "Home Phone #"
      },
      {
        Header: "Home State",
        accessor: "Home State"
      },
      {
        Header: "Home Street",
        accessor: "Home Street"
      },
      {
        Header: "Home Street Line 2",
        accessor: "Home Street Line 2"
      },
      {
        Header: "Home Zip",
        accessor: "Home Zip"
      },
      {
        Header: "Mailing Label",
        accessor: "Mailing Label"
      },
      {
        Header: "Middle Initial",
        accessor: "Middle Initial"
      },
      {
        Header: "Nickname",
        accessor: "Nickname"
      },
      {
        Header: "Other City",
        accessor: "Other City"
      },
      {
        Header: "Other Country",
        accessor: "Other Country"
      },
      {
        Header: "Other State",
        accessor: "Other State"
      },
      {
        Header: "Other Street",
        accessor: "Other Street"
      },
      {
        Header: "Other Street Line 2",
        accessor: "Other Street Line 2"
      },
      {
        Header: "Other Zip",
        accessor: "Other Zip"
      },
      {
        Header: "Personal Email",
        accessor: "Personal Email"
      },
      {
        Header: "Tax ID",
        accessor: "Tax ID"
      },
      {
        Header: "Work Email",
        accessor: "Work Email"
      },
      {
        Header: "Work Phone #",
        accessor: "Work Phone #"
      },
      {
        Header: "Contact External ID",
        accessor: "Contact External ID"
      },
      {
        Header: "Household External Id",
        accessor: "Household External Id"
      },
      {
        Header: "Prior CRM Contact ID",
        accessor: "Prior CRM Contact ID"
      },
      {
        Header: "Prior CRM Household ID",
        accessor: "Prior CRM Household ID"
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <div className="report-table">
      <table {...getTableProps()} className="table table-sm">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="report-table-header">
                  <div className="report-table-header-container">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
