import "./import_option_group.scss";

export default function ImportOptionGroup(props) {
  return (
    <div className="import-options-group">
      <div className="import-options-labels">
        {props.children}
      </div>
      <span className="text-muted">{props.label}</span>
    </div>
  );
}
