import "./login.scss";
import * as api from "../../modules/api";

/*
  Imitate Okta login screen
*/
export default function Login() {
  return (
    <>
      <div id="login-container-background">
      </div>
      <div id="login-container">
        <div id="login-header">
          <h2>Connecting to <img src="/images/favicon.ico" width="50px" alt="hightower logo" /></h2>
          <p>Sign-in with your Hightower Advisors account to access Hightower AIM services</p>
        </div>
        <div id="login-content">
          <div id="login-content-logo">
            <img src="/images/hightower_login_logo.png" alt="login logo" />
          </div>
          <div id="login-content-form">
            <div class="form-line-item">
              <p>Sign In</p>
            </div>
            <form method="POST" action={api.ALL_PATHS.loginPath} id="login-form">
              <div class="form-line-item">
                <label for="username-input" class="form-label">Username</label>
                <input type="email" class="form-control" name="username-input" id="username-input" />
              </div>
              <div class="form-line-item">
                <label for="password-input" class="form-label">Password</label>
                <input type="password" class="form-control" name="password-input" id="password-input" />
              </div>
              <div class="form-line-item">
                <input class="" type="checkbox" value="" name="remember-me-input" id="remember-me-input" />
                <label class="form-check-label" for="remember-me-input">&nbsp;Remember me</label>
              </div>
              <div class="form-line-item d-grid gap-2">
                <button id="sign-in-button" class="btn btn-primary" form="login-form" type="submit" value="Submit">Sign In</button>
              </div>
            </form>
            <div class="form-line-item">
              <p>Need help signing in?</p>
            </div>
          </div>
        </div>
        <div id="login-footer">
          <span>Powered by Okta</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </>
  );
}
