import { useState, useEffect } from "react";

import UploadHistoryTable from "./UploadHistoryTable/UploadHistoryTable";
import * as api from "../../../modules/api";

export default function UploadHistory() {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    async function getUploadHistory() {
      const response = api.getFileHistory();
      setHistory(response);
    }

    getUploadHistory();
  }, []);

  return (
    <>
      <UploadHistoryTable data={history} />
    </>
  );
}
