import "./welcome.scss";

export default function Welcome() {
  return (
      <>
        <div id="welcome-page-container">
          <h5>Welcome to Hightower AIM!</h5>
          <br/>
          <div><p>We know that migrating records from one system to another is difficult work. We built this tool to help you organize that work, and we built in several smart features to help you complete the work as quickly and efficiently as possible.</p></div>
          <div><p>Use the navigation bar on the left side of this screen to move around within AIM.</p></div>
          <div><p>
            The <b>Import Data</b> tool allows you to upload files from your CRM.<br/>
            All the data that you import and edit can be found in <b>Edit Contacts</b> and <b>Edit Financial Accounts</b>.<br/>
            Please use these table editors to add information and submit your completed records for review.
          </p></div>
          <div><p>When you need help: use the <b>Support</b> page to find information about how to use AIM and how to accomplish specific tasks.</p></div>
        </div>
      </>
  );
}
