import { useEffect, useState } from "react";

import UploadHistoryTable from "../UploadHistory/UploadHistoryTable/UploadHistoryTable";
import * as api from "../../../modules/api";

import "./project_dashboard.scss";
import BigNumberDisplay from "./BigNumberDisplay/BigNumberDisplay";

export default function ProjectDashboard(props) {
  const [contactCount, setContactCount] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(0);
  const [accountCount, setAccountCount] = useState(0);
  const [history, setHistory] = useState([]);


  useEffect(() => {
    async function getUploadHistory() {
      const response = await api.getFileHistory();
      setHistory(response.body.accounts);
      setFilesUploaded(response.body.accounts.length)
    }

    getUploadHistory();
  }, []);

  useEffect(() => {
    async function getDashboardValues() {
      const accounts = await api.getAccounts();
      const contacts = await api.getContacts();
      setContactCount(contacts.body.length);
      setAccountCount(accounts.body.length);
    }

    getDashboardValues();
  }, [])

  return (
    <>
      <div id="dashboard-container">
        <div id="dashboard-charts">
          <BigNumberDisplay icon="fa-user-circle" complete={contactCount} text="Contacts"/>
          <BigNumberDisplay icon="fa-file-alt" complete={filesUploaded} text="Files Uploaded"/>
          <BigNumberDisplay icon="fa-wallet" complete={accountCount} text="Financial Accounts"/>
        </div>
        <div>
          <UploadHistoryTable data={history} />
        </div>
      </div>
    </>
  );
}
