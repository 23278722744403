import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import * as api from "../../modules/api";
import AccountsReportTable from "../../components/Reviewer/ReportTable/AccountsReportTable";
import ContactsReportTable from "../../components/Reviewer/ReportTable/ContactsReportTable";

import "./reviewer.scss";

export default function Reviewer() {
  const [page, setPage] = useState('home'); // home, accounts, contacts, metrics
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountsReportData, setAccountsReportData] = useState([]);
  const [contactsReportData, setContactsReportData] = useState([]);
  const [metricsLink, setMetricsLink] = useState('');
  // const [metrics, setMetrics] = useState(null);

  // useEffect(() => {
  //   if (metrics !== null) {
  //     const container = window.d3.select('#histogram');

  //     const ref = container.selectAll('div')
  //       .data([metrics.renderingTime, metrics.networkTime]);

  //     ref.enter().append('div')
  //       .style('height', 0)
  //       .style('background-color', '#ddd')
  //       .transition()
  //         .ease(window.d3.easeBack)
  //         .duration(1200)
  //         .style('height', function (d) { return `${d * 10}px` })
  //         .style('background-color', 'orange')
  //   }
  // }, [metrics]);

  async function uploadToCas() {
    setUploading(true);
    try {
      const contacts = (await api.getContacts()).body.filter(contact => contact.status === 'promoted');
      const accounts = (await api.getAccounts()).body.filter(account => account.status === 'promoted');
      // const patchedContacts = await api.sendContactsToCas(contacts);
      // const patchedAccounts = await api.sendAccountsToCas(accounts);
      // await api.pushContactsToCas(patchedContacts.body);
      // await api.pushAccountsToCas(patchedAccounts.body);

      await api.sendContactsToCas(contacts);
      await api.sendAccountsToCas(accounts);
    }
    catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setUploading(false);
    }, 5000);
  }

  async function retrieveAccountsReport() {
    setLoading(true);
    try {
      const accountData = await api.getAccountsReport();
      setAccountsReportData(accountData.body);
    }
    catch (error) {
      console.error(error);
    }

    setLoading(false);
    setPage('accounts');
  }

  async function retrieveContactsReport() {
    setLoading(true);
    try {
      const contactData = await api.getContactsReport();
      setContactsReportData(contactData.body);
    }
    catch (error) {
      console.error(error);
    }

    setLoading(false);
    setPage('contacts');
  }

  // async function viewMetrics() {
  //   // const thingy = {
  //   //   ok: 45,
  //   //   plan: "example",
  //   //   domainLookupStart: 7.479,
  //   //   domainLookupEnd: 8.914,
  //   //   connectStart: 8.914,
  //   //   secureConnectionStart: 10.186,
  //   //   connectEnd: 22.558,
  //   //   requestStart: 22.558,
  //   //   responseStart: 26.23,
  //   //   responseEnd: 32.885
  //   // };
  //   // const thingies = [thingy];
  //   // const renderingTime = thingies.reduce((acc, i) => acc + i.responseEnd, 0) - thingies.reduce((acc, i) => acc + i.requestStart, 0);
  //   // const networkTime = thingies.reduce((acc, i) => acc + i.requestStart, 0) - thingies.reduce((acc, i) => acc + i.domainLookupStart, 0);

  //   // setMetrics({ renderingTime, networkTime });
  //   const link = api.getPaths().getQaMetricsPath;
  //   console.dir(link)
  //   setPage('metrics');
  //   setMetricsLink(link);
  // }

  let mainContentMarkup;
  if (uploading) {
    mainContentMarkup = (
      <div id="sending-to-cas-load">
        <i className="fas fa-circle-notch fa-spin fa-3x"></i>
        <p>Sending...</p>
      </div>
    )
  }
  else if (loading) {
    mainContentMarkup = (
      <div id="sending-to-cas-load">
        <i className="fas fa-circle-notch fa-spin fa-3x"></i>
        <p>Loading...</p>
      </div>
    )
  }
  else {
    if (page === 'home') {
      mainContentMarkup = <p>This page is a placeholder, offering minimal reviewer actions for testing. It will be significantly enhanced in the future.</p>
    }
    else if (page === 'accounts') {
      mainContentMarkup = (
        <div id="report-tables-container">
          <AccountsReportTable data={accountsReportData}/>
        </div>
      )
    }
    else if (page === 'contacts') {
      mainContentMarkup = (
        <div id="report-tables-container">
          <ContactsReportTable data={contactsReportData}/>
        </div>
      )
    }
    else if (page === 'metrics') {
      // mainContentMarkup = (
      //   <div id="metrics-container">
      //     <div id="histogram">
      //     </div>
      //     <div className="histogram-labels">
      //       <span className="text-muted">Rendering Time</span>
      //       <span className="text-muted">Network Time</span>
      //     </div>
      //     <div className="histogram-labels">
      //       <span className="text-muted">{metrics.renderingTime.toFixed(1)}s</span>
      //       <span className="text-muted">{metrics.networkTime.toFixed(1)}s</span>
      //     </div>
      //   </div>
      // )
      mainContentMarkup = (
        <Redirect to="https://f4hcoy6e12.execute-api.us-east-1.amazonaws.com/ui/qa" />
      )
    }
  }

  return (
    <div id="reviewer-container">
      <nav id="left-nav-reviewer">
        <div id="corner-logo">
          <h1 className="aim-logo">AIM</h1>
          <span className="aim-logo-subtext">Advisor Intake Manager</span>
        </div>
        <div id="nav-link-container">
          <div className={`aim-nav-link clickable`} onClick={() => { uploadToCas() }}>
            <i className="fa fa-file-upload"></i>
            <span className="aim-nav-link-label">Send Everything to CAS</span>
          </div>
          <div className={`aim-nav-link clickable`} onClick={() => { retrieveAccountsReport() }}>
            <i className="fa fa-file-alt"></i>
            <span className="aim-nav-link-label">View Accounts CAS Report</span>
          </div>
          <div className={`aim-nav-link clickable`} onClick={() => { retrieveContactsReport() }}>
            <i className="fa fa-file-alt"></i>
            <span className="aim-nav-link-label">View Contacts CAS Report</span>
          </div>
          <div className={`aim-nav-link clickable`} onClick={() => { window.location.replace('https://f4hcoy6e12.execute-api.us-east-1.amazonaws.com/ui/qa') }}>
            <i className="fa fa-chart-bar"></i>
            <span className="aim-nav-link-label">View QA Metrics</span>
          </div>
        </div>
        <div id="powered-by-ht-logo-container">
          <img id="powered-by-ht-logo" src="/images/PoweredByHT.png" alt="aim logo"/>
        </div>
      </nav>
      <div id="reviewer-content-body">
        {mainContentMarkup}
      </div>
    </div>
  )
}
