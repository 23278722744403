import { useState } from 'react';
import { usePopper } from 'react-popper';
import { matchSorter } from "match-sorter";

import "./hide_columns_button.scss";

export default function HideColumnsButton(props) {
  const [columnFilter, setColumnFilter] = useState('');
  const [showHideColumns, setShowHideColumns] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { update, styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement }
      },
      {
        name: 'offset',
        options: { offset: [0, 10] }
      }
    ],
  });

  function showHideColumnsTooltip() {
    setShowHideColumns(!showHideColumns);
    update();
  }

  function onChange(label) {
    const shallowObj = { ...props.hiddenColumnIds };
    shallowObj[label] = !props.hiddenColumnIds[label];
    props.setHiddenColumnIds(shallowObj);
  }

  const hideColumnFiltered = matchSorter(props.allColumns.map(column => column.label), columnFilter);

  return (
    <>
      <div className="editor-control-button" ref={setReferenceElement} onClick={showHideColumnsTooltip}>
        <i className={`fas fa-eye-slash`}></i>
        <span>Select Columns</span>
      </div>

      <div id="fields-control" ref={setPopperElement} style={styles.popper} className={`${showHideColumns ? '' : 'invisible'}`} {...attributes.popper}>
        <input id="fields-control-input" type="text" placeholder="search columns..." value={columnFilter} onChange={event => setColumnFilter(event.target.value)}/>
        <div id="fields-control-select-container">
          {props.allColumns.map((column) => {
            return (
              <div key={column.label} className={`${hideColumnFiltered.find(name => name === column.label) ? 'field-control-select' : 'invisible'}`}>
                <input type="checkbox" checked={!props.hiddenColumnIds[column.label]} onChange={() => { onChange(column.label) }} />
                <span>{column.label.substring(0, 24)}</span>
              </div>
            )
          })}
        </div>
        <div ref={setArrowElement} style={styles.arrow} />
      </div>
    </>
  )
}
