import { useEffect } from "react";

/*

    Accepts:
      - red
      - yellow
      - green

    Note, this component uses the front-end library D3 quite heavily

*/
export default function StatsDisplay(props) {
  useEffect(() => {
    let data = [
      { id: 'red', amount: props.red, fill: 'url(#redGradient)', textFill: '#3f3f3f', minX: 0 },
      { id: 'yellow', amount: props.yellow, fill: '#d9b304', textFill: '#3f3f3f', minX: 30 },
      { id: 'green', amount: props.green, fill: 'url(#greenGradient)', textFill: '#3f3f3f', minX: 60 },
    ];
    
    const sum = props.red + props.yellow + props.green;
    const width = props.width;
    
    const svg = window.d3.select(`#${props.svgId}`);
    const ref = svg.selectAll("rect")
        .data(data, function(d) { return d.id; });
    
    /*

        Construct our "data bars" or coloured rectangles

    */
    ref.enter().append('rect')
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 0)
      .attr("height", 10)
      .style('fill', function(d) { return d.fill })
      .transition()
        .ease(window.d3.easeExp)
        .duration(2000)
        .attr("x", function(d, i) { return data.slice(0, i).reduce((acc, e) => acc + e.amount / sum * width, 0) })
        .attr("width", function(d) { return d.amount / sum * width - 3 });
          
    /*

        Add text to make the amount more explicit

    */
    ref.enter().append('text')
      .attr("x", 0)
      .attr("y", 27)
      .text(function(d) { return `${(d.amount / sum * 100).toFixed(0)}%` })
      .style('fill', function(d) { return d.textFill })
      .style('font-weight', 'bold')
      .transition()
        .ease(window.d3.easeExp)
        .duration(2000)
        .attr("x", function(d, i) { return Math.min(Math.max(data.slice(0, i).reduce((acc, e) => acc + e.amount / sum * width, 0), d.minX), props.width - 25 ) })
  });
  
  return (
      <svg id={props.svgId} width={props.width} height="30">
        <defs>
          <linearGradient id="redGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#db2929", stopOpacity: "1"}} />
            <stop offset="100%" style={{ stopColor: "#971620", stopOpacity: "1" }} />
          </linearGradient>
          
          <linearGradient id="greenGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: "#1d7f13", stopOpacity:"1" }} />
            <stop offset="100%" style={{ stopColor: "#28b121", stopOpacity:"1" }} />
          </linearGradient>
        </defs>
      </svg>
  );
}
