import { useMemo } from "react";
import { useTable } from "react-table";

import "./fill_defaults_table.scss";

export default function FillDefaultsTable(props) {
  const columns = useMemo(() => [
      {
        Header: 'Field Name',
        accessor: 'name',
      },
      {
        Header: 'Default Value',
        accessor: 'default_value',
        Cell: (cellInfo) => {
          if (cellInfo.value === 'No') {
            return (
              <div className="fill-defaults-table-cell">
                <select name="cars" id="cars">
                  <option value="main">{cellInfo.value}</option>
                  <option value="yes">Yes</option>
                </select>
              </div>
            )
          }
          else if (cellInfo.value === 'COBD') {
            return (
              <div className="fill-defaults-table-cell">
                <select name="cars" id="cars">
                  <option value="main">{cellInfo.value}</option>
                  <option value="yes">new</option>
                </select>
              </div>
            )
          }
          else if (cellInfo.value === 'based on team due diligence') {
            return (
              <div className="fill-defaults-table-cell">
                <span>{cellInfo.value}</span>
              </div>
            )
          }
          else {
            return (
              <div className="fill-defaults-table-cell">
                <input type="text" value={cellInfo.value} />
              </div>
            )
          }
        }
      },
  ], []);

  const data = useMemo(() => [
      {
          name: 'Is the acct ERISA AHA?',
          default_value: 'No',
      },
      // {
      //     name: 'Wealth Management Service Type',
      //     default_value: 'based on team due diligence',
      // },
      {
          name: 'Brokerage Service Type',
          default_value: 'Full Service',
      },
      // {
      //     name: 'Type of Discretion',
      //     default_value: 'based on team due diligence',
      // },
      {
          name: 'Investment Policy Statement',
          default_value: 'No',
      },
      {
          name: 'Investment Restrictions',
          default_value: 'No',
      },
      {
          name: 'new or COBD',
          default_value: 'COBD',
      },
      {
          name: 'Commissionable Account Status',
          default_value: 'Draft',
      },
      {
          name: 'part of advisory agreement',
          default_value: 'No',
      },
      {
          name: 'Fee Payment Type',
          default_value: 'Deducted from Custodian Account',
      },
      // {
      //     name: 'Fee Type',
      //     default_value: 'based on team due diligence',
      // },
      {
          name: 'Asset manager',
          default_value: 'Advisor on Account',
      },
      {
          name: "Money Manager (re-title to 'Account Management Type')",
          default_value: 'Rep as PM',
      },
      {
          name: 'Rollover?',
          default_value: 'No',
      },
      {
          name: 'Is this a Robo acct?',
          default_value: 'No',
      },
      {
          name: 'Will this acct hold Alternative Investments?',
          default_value: 'No',
      },
      {
          name: 'Asset Manager (HTMM, Direct 3rd Party, Envestnet Mgr, HT Internal, Advisor)',
          default_value: 'Advisor on Account if column AY=Rep as PM',
      },
      {
          name: 'Envestnet Platform Used (Yes/No)',
          default_value: 'No',
      },
      {
          name: 'Has Solicitor Agreement',
          default_value: 'No',
      },
      {
          name: 'Is this a Retirement Account?',
          default_value: 'No',
      },
      {
          name: 'Government ID Type',
          default_value: "State Driver's License",
      },
  ], []);

  const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows
  } = useTable({ columns, data });

  return (
    <>
      <table id="fill-defaults-table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} className="fill-defaults-table-header">
              {headerGroup.headers.map((column, i) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                    ? column.isSortedDesc
                        ? <i class="fas fa-sort-down"></i>
                        : <i class="fas fa-sort-up"></i>
                    : ''}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <tr {...row.getRowProps()} className="fill-defaults-table-row">
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  );
}
