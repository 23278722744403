import { useState } from "react";
import { Redirect } from "react-router-dom";

import FillDefaultsTable from "./FillDefaultsTable/FillDefaultsTable";
import TableButton from "../../shared/TableButton/TableButton";

import "./fill_defaults.scss";

export default function FillDefaults(props) {
  const [back, setBack] = useState(false);
  const [next, setNext] = useState(false);

  let redirectMarkup;
  if (next) {
    redirectMarkup = <Redirect to={`/projects/${props.activeProject.id}/import/confirm`} />
  }
  else if (back) {
    redirectMarkup = <Redirect to={`/projects/${props.activeProject.id}/import`} />
  }

  return (
    <>
      <div id="fill-defaults-table-controls">
        <div>
          {redirectMarkup}
          <TableButton icon="fa-arrow-left" text="Back" onClick={() => { setBack(true) }}/>
          <TableButton icon="fa-arrow-right" text="Next" onClick={() => { setNext(true) }}/>
        </div>
      </div>
      <FillDefaultsTable />
    </>
  );
}
