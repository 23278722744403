import { useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Header from "../../components/Header/Header";
import Navbar from "../../components/Navbar/Navbar";
import Support from "../../components/HeroSpace/Support/Support";
import Welcome from "../../components/HeroSpace/Welcome/Welcome";
import GlobalDataAdmin from "../../components/HeroSpace/GlobalDataAdmin/GlobalDataAdmin";
import FillDefaults from "../../components/HeroSpace/FillDefaults/FillDefaults";
import ConfirmImport from "../../components/HeroSpace/ConfirmImport/ConfirmImport";
import ImportData from "../../components/HeroSpace/ImportData/ImportData";
import UploadHistory from "../../components/HeroSpace/UploadHistory/UploadHistory";
import EditContacts from "../../components/HeroSpace/EditContacts/EditContacts";
import EditAgreements from "../../components/HeroSpace/EditAgreements/EditAgreements";
import ProjectDashboard from "../../components/HeroSpace/ProjectDashboard/ProjectDashboard";
import ProjectOverview from "../../components/HeroSpace/ProjectOverview/ProjectOverview";

import "./project.scss";

/*
  Entrypoint for admin/advisor project(s) work
*/
export default function Projects() {
  let savedActiveProject = null;
  if (localStorage.getItem('activeProject')) {
      savedActiveProject = JSON.parse(localStorage.getItem('activeProject'));
  }
  const [activeProject, setActiveProject] = useState(savedActiveProject);

  let baseUpdates = { duplication: 0, contact: 0, agreement: 0 };
  if (localStorage.getItem('updates')) {
      baseUpdates = JSON.parse(localStorage.getItem('updates'));
  }
  const [duplicationUpdates, setDuplicationUpdates] = useState(baseUpdates.duplication);
  const [contactUpdates, setContactUpdates] = useState(baseUpdates.contact);
  const [agreementUpdates, setAgreementUpdates] = useState(baseUpdates.agreement);

  let match = useRouteMatch();

  return (
    <div id="editor-container">
      <Navbar activeProject={activeProject} duplicationUpdates={duplicationUpdates} contactUpdates={contactUpdates} agreementUpdates={agreementUpdates} />
      <div id="right-content">
        <Header activeProject={activeProject} />
        <div id="editor-space">
          <Switch>
            <Route path={`${match.url}/support/welcome`}>
              <Welcome />
            </Route>
            <Route path={`${match.url}/support`}>
              <Support />
            </Route>
            <Route path={`${match.url}/dataadmin`}>
              <GlobalDataAdmin />
            </Route>
            <Route path={`${match.url}/:id/import/defaults`}>
              <FillDefaults activeProject={activeProject} />
            </Route>
            <Route path={`${match.url}/:id/import/confirm`}>
              <ConfirmImport
                addedContacts={23}
                addedAgreements={67}
                updatedContacts={13}
                activeProject={activeProject}
                onConfirm={() => {
                  const newDuplicates = 10;
                  const newContacts = 36;
                  const newAgreements = 67;
                  if (localStorage.getItem('updates')) {
                    const updates = JSON.parse(localStorage.getItem('updates'));
                    setDuplicationUpdates(newDuplicates + updates.duplication);
                    setContactUpdates(newContacts + updates.contact);
                    setAgreementUpdates(newAgreements + updates.agreement);
                    localStorage.setItem(
                      'updates',
                      JSON.stringify({
                          duplication: newDuplicates + updates.duplication,
                          contact: newContacts + updates.contact,
                          agreement: newAgreements + updates.agreement
                      }));
                  }
                  else {
                    const updates = { duplication: newDuplicates, contact: newContacts, agreement: newAgreements };
                    localStorage.setItem('updates', JSON.stringify(updates));
                    setDuplicationUpdates(newDuplicates);
                    setContactUpdates(newContacts);
                    setAgreementUpdates(newAgreements);
                  }
                }}/>
            </Route>
            <Route path={`${match.url}/:id/import`}>
                <ImportData activeProject={activeProject} />
            </Route>
            <Route path={`${match.url}/:id/history`}>
                <UploadHistory activeProject={activeProject} />
            </Route>
            <Route path={`${match.url}/:id/editcontacts`}>
                <EditContacts />
            </Route>
            <Route path={`${match.url}/:id/editagreements/advisory`}>
                <EditAgreements activeProject={activeProject} tab="advisory" />
            </Route>
            <Route path={`${match.url}/:id/editagreements/brokerage`}>
                <EditAgreements activeProject={activeProject} tab="brokerage" />
            </Route>
            <Route path={`${match.url}/:id/editagreements/commissionable`}>
                <EditAgreements activeProject={activeProject} tab="commissionable" />
            </Route>
            <Route path={`${match.url}/:id/editagreements/profservices`}>
                <EditAgreements activeProject={activeProject} tab="profservices" />
            </Route>
            <Route path={`${match.url}/:id/editagreements`}>
                <EditAgreements activeProject={activeProject} tab="advisory" />
            </Route>
            <Route path={`${match.url}/:id/support/welcome`}>
                <Welcome />
            </Route>
            <Route path={`${match.url}/:id/support`}>
                <Support />
            </Route>
            <Route path={`${match.url}/:id`}>
                <ProjectDashboard />
            </Route>
            <Route path={`${match.url}`}>
                <ProjectOverview setActiveProject={setActiveProject} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
