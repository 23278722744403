import { useState } from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import TableButton from "../TableButton/TableButton";
import PromoteModal from "./PromoteModal/PromoteModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import BulkEditModal from "./BulkEditModal/BulkEditModal";
import HideColumnsButton from "./HidColumnsButton/HideColumnsButton";
import FreezeColumnsButton from "./FreezeColumnsButton/FreezeColumnsButton";
import DatePickerCell from "../DatePickerCell/DatePickerCell";
import SelectPageSize from "./SelectPageSize/SelectPageSize";
import * as api from "../../../modules/api";

import "./record_table.scss";

export default function RecordTable(props) {
  const [gridApi, setGridApi] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]); 

  // frozen columns
  const [frozenColumnIds, setFrozenColumnIds] = useState([]);

  // hidden columns
  const [hiddenColumnIds, setHiddenColumnIds] = useState([]);

  // page size
  const [pageSize, setPageSize] = useState(15);

  // visible row options
  const [invalidRowsVisible, setInvalidRowsVisible] = useState(true);
  const [invalidRowIds, setInvalidRowIds] = useState([]);

  const [demotedRowsVisible, setDemotedRowsVisible] = useState(true);
  const [demotedRowIds, setDemotedRowIds] = useState([]);

  const [deletedRowsVisible, setDeletedRowsVisible] = useState(true);
  const [deletedRowIds, setDeletedRowIds] = useState([]);

  const [promotedRowsVisible, setPromotedRowsVisible] = useState(true);
  const [promotedRowIds, setPromotedRowIds] = useState([]);

  // modals
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [showPromoteModal, setShowPromoteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const allColumns = props.headers.reduce((acc, header) => acc.concat(header.columns), []);

  let tableButtonMarkup;
  if (selectedRows.length > 1) {
    tableButtonMarkup = (
      <>
        {/* <TableButton icon="fa-trash-alt" text={`Delete (${selectedRows.length} records)`} onClick={() => { setShowDeleteModal(true) }}/> */}
        {/* <TableButton icon="fa-edit" text={`Edit (${selectedRows.length} records)`} onClick={() => { setShowBulkEditModal(true) }}/> */}
        <TableButton icon="fa-file-upload" text={`Promote (${selectedRows.length} records)`} onClick={() => { setShowPromoteModal(true) }}/>
      </>
    )
  }
  else if (selectedRows.length === 1) {
    tableButtonMarkup = (
      <>
        {/* <TableButton icon="fa-trash-alt" text="Delete" onClick={() => { setShowDeleteModal(true) }}/> */}
        {/* <TableButton icon="fa-edit" text="Edit" onClick={() => { setShowBulkEditModal(true) }}/> */}
        <TableButton icon="fa-file-upload" text="Promote" onClick={() => { setShowPromoteModal(true) }}/>
      </>
    )
  }

  const onGridReady = (params) => {
    setGridApi(params.api);
  }

  const onSelectionChanged = (event) => {
    setSelectedRows(gridApi.getSelectedNodes());
  };

  const onPageSizeChanged = (newPageSize) => {
    if (typeof newPageSize === 'number') {
      gridApi.paginationSetPageSize(newPageSize);
    }
    setPageSize(newPageSize);
  }

  const columnMarkup = props.headers.map((header) => {
    const subColumns = header.columns.map((subheader) => {
      let editor = 'agTextCellEditor';
      if (subheader.editorType === 'select') {
        editor = 'agSelectCellEditor';
      }
      else if (subheader.editorType === 'date') {
        editor = 'dateEditorComponent'
      }

      return (
        <AgGridColumn
          field={subheader.field}
          hide={!!hiddenColumnIds[subheader.label]}
          pinned={!!frozenColumnIds[subheader.label] ? 'left' : ''}
          cellEditor={editor}
          cellEditorParams={ subheader.editorType === 'select' ? { values: subheader.selectValues } : undefined }
          resizable
          editable={(params) => { return params.data.status !== 'promoted' && params.data.status !== 'deleted' }}
          sortable
          filter></AgGridColumn>
      )
    });

    return (
      <AgGridColumn headerName={header.label}>
        {subColumns}
      </AgGridColumn>
    )
  });

  async function editRows(rows) {
    const response = await api.editRows(props.isAccount, rows.map(row => row.data));

    if (response[0].ok) {
      const newRowData = rows.map((row) => {
        row.data.invalid = false;
        return row.data;
      })
      gridApi.applyTransaction({ update: newRowData });
      const rowIds = rows.map(row => row.id);
      setInvalidRowIds([...invalidRowIds.filter((rowId) => !rowIds.includes(rowId))]);
    }
    else {
      const newRowData = rows.map((row) => {
        row.data.invalid = true;
        return row.data;
      })
      gridApi.applyTransaction({ update: newRowData });
      setInvalidRowIds([...invalidRowIds, ...rows.map(row => row.id)]);
    }
  }

  // async function deleteRows(rows) {
  //   const response = await api.deleteRows(props.isAccount, rows.map(row => row.data));

  //   if (response.ok) {
  //     const newRowData = rows.map((row) => {
  //       row.data.deleted = true;
  //       return row.data;
  //     })
  //     gridApi.applyTransaction({ update: newRowData });
  //     setDeletedRowIds([...deletedRowIds, ...rows.map(row => row.id)]);
  //   }
  //   else {
  //     console.error('Error promoting rows');
  //   }
  // }

  async function promoteRows(rows) {
    const response = await api.promoteRows(props.isAccount, rows.map(row => row.data));

    for (const res of response) {
      if (res.ok) {
        const newRowData = rows.map((row) => {
          row.data.status = "promoted";
          return row.data;
        })
        gridApi.applyTransaction({ update: newRowData });
        setPromotedRowIds([...promotedRowIds, ...rows.map(row => row.id)]);
      }
      else {
        console.error('Error promoting rows');
      }
    }
    }

  // async function demoteRows(rows) {
  //   const response = await api.demoteRows(props.isAccount, rows.map(row => row.data));

  //   if (response.ok) {
  //     const newRowData = rows.map((row) => {
  //       row.data.demoted = true;
  //       return row.data;
  //     })
  //     gridApi.applyTransaction({ update: newRowData });
  //     setDemotedRowIds([...demotedRowIds, ...rows.map(row => row.id)]);
  //   }
  //   else {
  //     console.error('Error demoting rows');
  //   }
  // }

  function isRowSelectable(rowNode) {
    return rowNode.data.status !== 'promoted' && rowNode.data.status !== 'deleted';
  }

  return (
    <>
      {/* Modals */}
      {/* <BulkEditModal
        show={showBulkEditModal}
        columns={allColumns}
        recordCount={selectedRows.length}
        onEdit={() => {
          // do something magical
          setShowBulkEditModal(false);
        }}
        onCancel={() => { setShowBulkEditModal(false) }} /> */}
      <PromoteModal
        show={showPromoteModal}
        recordCount={selectedRows.length}
        onPromote={() => {
          promoteRows(selectedRows);
          setShowPromoteModal(false);
        }}
        onCancel={() => { setShowPromoteModal(false) }} />
      {/* <DeleteModal
        show={showDeleteModal}
        recordCount={selectedRows.length}
        onDelete={() => {
          deleteRows(selectedRows);
          setShowDeleteModal(false);
        }}
        onCancel={() => { setShowDeleteModal(false) }} /> */}

      <div id="editor-controls" className="pagination">
        {/* Selection Actions */}
        <div id="selection-controls">
          {tableButtonMarkup}
        </div>

        {/* "Always Available" Actions */}
        <div id="always-available-controls">
          {/* <TableButton icon="fa-plus" text="Add Row" onClick={() => addRow()}/> */}
          <HideColumnsButton allColumns={allColumns} hiddenColumnIds={hiddenColumnIds} setHiddenColumnIds={setHiddenColumnIds} />
          <FreezeColumnsButton allColumns={allColumns} frozenColumnIds={frozenColumnIds} setFrozenColumnIds={setFrozenColumnIds} />
          <SelectPageSize pageSize={pageSize} setPageSize={onPageSizeChanged} />
        </div>
      </div>

      <div id="editor-table-container" className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          rowData={props.data}
          pagination
          paginationPageSize={pageSize}
          rowSelection="multiple"
          suppressRowClickSelection
          onSelectionChanged={onSelectionChanged}
          rowClassRules={{
            'promoted-row': function(params) { return params.data.status === 'promoted' },
            'demoted-row': function(params) { return params.data.status === 'demoted' },
            'deleted-row': function(params) { return params.data.status === 'deleted' },
            'invalid-row': function(params) { return params.data.status === 'invalid' },
          }}
          isRowSelectable={isRowSelectable}
          enterMovesDownAfterEdit
          frameworkComponents={{
            'dateEditorComponent': DatePickerCell
          }}
          onCellValueChanged={(result) => { editRows([result.node]) }}>
          <AgGridColumn
            resizable
            checkboxSelection
            headerCheckboxSelection
            headerCheckboxSelectionFilteredOnly
            lockPosition
            pinned={'left'}
            initialWidth={50}>
          </AgGridColumn>
          {columnMarkup}
        </AgGridReact>
      </div>
    </>
  )
}
