import { useEffect, useState } from "react";

export default function BigNumberDisplay(props) {

  return (
    <div className="circle-chart-container">
      <div className="big-number" width="300" height="300">
        <p>{props.complete}</p>
      </div>
      <span className="text-muted chart-label"><i className={`fas ${props.icon}`}></i> {props.text}</span>
      {/* <span className="text-muted chart-data">{props.complete} / {props.total} ({props.percent}%)</span> */}
    </div>
  )
}
