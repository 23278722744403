import { useMemo } from "react";
import { useTable } from 'react-table';

import "./report_table.scss";
 
export default function AccountsReportTable(props) {
  const data = useMemo(() => props.data, [props.data])

  const columns = useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "Account Name"
      },
      {
        Header: "Commissionable Account Status",
        accessor: "Commissionable Account Status"
      },
      {
        Header: "Expected Commission Pay Frequency",
        accessor: "Expected Commission Pay Frequency"
      },
      {
        Header: "Expected Commission Recurrence Frequency",
        accessor: "Expected Commission Recurrence Frequency"
      },
      {
        Header: "Commissionable HT Registration Type",
        accessor: "Commissionable HT Registration Type"
      },
      {
        Header: "New Business or COBD?",
        accessor: "New Business or COBD?"
      },
      {
        Header: "Part of advisory agreement",
        accessor: "Part of advisory agreement"
      },
      {
        Header: "Is the Primary Account Owner the Signer for the COBD Form?",
        accessor: "Is the Primary Account Owner the Signer for the COBD Form?"
      },
      {
        Header: "CAA#",
        accessor: "CAA#"
      },
      {
        Header: "Account Management Type",
        accessor: "Account Management Type"
      },
      {
        Header: "What is the type of outside manager used for this Account?",
        accessor: "What is the type of outside manager used for this Account?"
      },
      {
        Header: "Custodian Name",
        accessor: "Custodian Name"
      },
      {
        Header: "Is the Envestment Platform Used for Managing this Account?",
        accessor: "Is the Envestment Platform Used for Managing this Account?"
      },
      {
        Header: "Fee Payment Type",
        accessor: "Fee Payment Type"
      },
      {
        Header: "Custody Fee Type",
        accessor: "Custody Fee Type"
      },
      {
        Header: "Is this a retirement Account?",
        accessor: "Is this a retirement Account?"
      },
      {
        Header: "Is this a Robo acct?",
        accessor: "Is this a Robo acct?"
      },
      {
        Header: "Is this account a Rollover?",
        accessor: "Is this account a Rollover?"
      },
      {
        Header: "Will this acct hold Alternative Investments?",
        accessor: "Will this acct hold Alternative Investments?"
      },
      {
        Header: "Does the recurring fee have an end date?",
        accessor: "Does the recurring fee have an end date?"
      },
      {
        Header: "Periodicity",
        accessor: "Periodicity"
      },
      {
        Header: "Will Client pay for services in Installments?",
        accessor: "Will Client pay for services in Installments?"
      },
      {
        Header: "Account External ID",
        accessor: "Account External ID"
      },
      {
        Header: "Agreement External ID",
        accessor: "Agreement External ID"
      },
    ],
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <div className="report-table">
      <table {...getTableProps()} className="table table-sm">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="report-table-header">
                  <div className="report-table-header-container">
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}