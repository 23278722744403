import { useMemo } from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { Link } from "react-router-dom";

import StatsDisplay from "../../../shared/StatsDisplay/StatsDisplay";

import "./projects_table.scss";

export default function ProjectsTable(props) {
  const columns = useMemo(() => [
    {
      Header: <span><i class="fas fa-dice-d6"></i> Project Name</span>,
      accessor: 'name',
    },
    {
      Header: <span><i class="fas fa-user-circle"></i> Status</span>,
      accessor: 'status',
      Cell: (cellInfo) => {
          let badgeClass;
          if (cellInfo.value === 'ACTIVE') {
              badgeClass = 'bg-success';
          }
          else if (cellInfo.value === 'CLOSED') {
              badgeClass = 'bg-danger';
          }
          else {
              badgeClass = 'bg-secondary';
          }

          return (
              <div>
                  <span className={`table-status-column badge ${badgeClass}`}>{cellInfo.value}</span>
              </div>
          );
      }
    },
    {
      Header: <span><i class="fas fa-clock"></i> Created Date</span>,
      accessor: 'created_at',
      disableFilters: true,
      Cell: (cellInfo) => {
          const display = window.moment(cellInfo.value).format('YYYY-MM-DD')

          return (
              <div>
                  <span>{display}</span>
              </div>
          )
      }
    },
    {
      Header: <span><i class="fas fa-user-circle"></i> Created By</span>,
      accessor: 'created_by',
      Cell: (cellInfo) => {
          return (
              <div>
                  <i class="fas fa-user-circle fa-2x"></i>
                  <span>J. Willoby</span>
              </div>
          )
      }
    },
    {
      Header: <span><i class="fas fa-user-circle"></i> Advisors</span>,
      accessor: 'advisors',
      disableFilters: true,
      Cell: (cellInfo) => {
          return (
              <div>
                  <span>{cellInfo.value.length}</span>
              </div>
          )
      }
    },
    {
      Header: <span><i class="fas fa-user-circle"></i> Hightower</span>,
      accessor: 'ht_advisors',
      disableFilters: true,
      Cell: (cellInfo) => {
          return (
              <div>
                  <span>{cellInfo.value.length}</span>
              </div>
          )
      }
    },
    {
      Header: 'Financial Account',
      columns: [
        {
          Header: <span><i class="fas fa-exclamation-circle"></i> Progress</span>,
          accessor: 'record_count.green',
          disableFilters: true,
          Cell: (cellInfo) => {
            if (cellInfo.data[cellInfo.row.index].record_count.total > 0) {
              return (
                <StatsDisplay
                  red={cellInfo.data[cellInfo.row.index].record_count.red}
                  yellow={cellInfo.data[cellInfo.row.index].record_count.yellow}
                  green={cellInfo.data[cellInfo.row.index].record_count.green}
                  width={200}
                  svgId={`stats-${cellInfo.data[cellInfo.row.index].id}`} />
              )
            }
            else {
              return (
                <p>- No Stats -</p>
              )
            }
          }
        },
        {
          Header: <span><i class="fas fa-check-double"></i> Total</span>,
          accessor: 'record_count.total',
          disableFilters: true,
        }
      ]
    }
  ], []);

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = useMemo(() => ({
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
  }), []);

  const filterTypes = useMemo(() => ({
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true
      })
    },
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({ columns, data: props.projects, defaultColumn, filterTypes }, useFilters, useSortBy);

  return (
    <>
      <table id="project-overview-table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                let sortControls;
                if (index === 1) {
                  sortControls = (
                    <span className="sort-controls">
                      {column.isSorted
                      ? column.isSortedDesc
                        ? <i class="fas fa-sort-down"></i>
                        : <i class="fas fa-sort-up"></i>
                      : <i class="fas fa-sort"></i>}
                    </span>
                  );
                }
                
                return (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="project-overview-table-header">
                    <div className="record-table-header-label">
                      <span>
                        {column.render('Header')}
                      </span>
                      {sortControls}
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}

          {/* Filter level headers */}
          <tr {...headerGroups[1].getHeaderGroupProps()}>
            {headerGroups[1].headers.map((column) => {
              let headerContent;
              if (column.canFilter) {
                headerContent = (
                  <div className="record-table-header-filter">
                    <i class="fas fa-filter"></i>
                    {column.render('Filter')}
                    <i class="fas fa-info-circle"></i>
                  </div>
                );
              }

              return (
                <th className="record-table-header" {...column.getHeaderProps()}>
                  {headerContent}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <tr
                className={`project-overview-table-row clickable`}
                onClick={() => {
                  localStorage.setItem('activeProject', JSON.stringify(row.original))
                  props.setActiveProject(row.original);
                }}
                {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className="project-overview-table-cell">
                      <Link to={`projects/${row.original.id}`}>
                        {cell.render('Cell')}
                      </Link>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  );
}
