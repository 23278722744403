import { useState } from "react";

export default function ConfirmImport(props) {
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);

  function importRecords() {
    setLoading(true);

    setTimeout(() => {
      if (props.onConfirm) {
        props.onConfirm();
      }

      setComplete(true);
      setLoading(false);
    }, 3000);
  }

  let messageMarkup;
  if (loading) {
    messageMarkup = (
      <div id="confirm-message-container">
        <div id="confirm-message-body">
          <i class="fas fa-circle-notch fa-spin fa-3x"></i>
          <p>Importing...</p>
        </div>
      </div>
    )
  }
  else if (complete) {
    messageMarkup = (
      <div id="confirm-message-container">
        <div id="confirm-message">
          <div id="confirm-message-header">
            <p>Import successful:</p>
          </div>
          <div id="confirm-message-body">
            <ul>
              <li>{props.addedContacts} contacts were added</li>
              <li>{props.addedAgreements} financial accounts were added</li>
              <li>{props.updatedContacts} contacts were updated</li>
            </ul>
          </div>
          <div id="confirm-message-footer">
            <button onClick={() => { window.location.replace(`/projects/${props.activeProject.id}/editcontacts`) }}>View</button>
            <button onClick={() => { window.location.replace(`/projects/${props.activeProject.id}/import`) }}>Import Another</button>
          </div>
        </div>
      </div>
    )
  }
  else {
    messageMarkup = (
      <div id="confirm-message-container">
        <div id="confirm-message">
          <div id="confirm-message-header">
            <p>Confirming will:</p>
          </div>
          <div id="confirm-message-body">
            <ul>
              <li>add {props.addedContacts} contacts</li>
              <li>add {props.addedAgreements} financial accounts</li>
              <li>update {props.updatedContacts} contacts</li>
            </ul>
            <p>Would you like to proceed?</p>
          </div>
          <div id="confirm-message-footer">
            <button onClick={importRecords}>Confirm</button>
            <button onClick={() => { window.location.replace(`/projects/${props.activeProject.id}/import`) }}>Cancel</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {messageMarkup}
    </>
  );
}
