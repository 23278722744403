import { useState, useEffect } from "react";

import * as api from "../../../modules/api";

import ProjectsTable from "./ProjectsTable/ProjectsTable";

export default function ProjectOverview(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    async function getProjects() {
      try {
        const projects = await api.getProjects();
        setProjects(projects.body);
        setError(null);
      }
      catch (fetchError) {
        console.error(fetchError);
        setError('Error retrieving project listing');
      }

      setLoading(false);
    } 

    getProjects();
  }, []);

  let projectListMarkup;
  if (error) {
    projectListMarkup = (
      <div>
        {error}
      </div>
    )
  }
  else if (loading) {
    projectListMarkup = (
      <div>
        Loading...
      </div>
    )
  }
  else {
    projectListMarkup = <ProjectsTable key={projects.id} projects={projects} setActiveProject={props.setActiveProject} />
  }

  return (
    <>
      <div id="project-table-controls">
        <button>Create New Project</button>
      </div>
      {projectListMarkup}
    </>
  );
}
