import { BrowserRouter, Switch, Route } from "react-router-dom";

import Login from "./pages/Login/Login";
import Projects from "./pages/Projects/Projects";
import Setup from "./pages/Setup/Setup";
import Reviewer from "./pages/Reviewer/Reviewer";
import './App.scss';

/*
  Entrypoint for entire application
*/
export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/setup">
            <Setup />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/reviewer">
            <Reviewer />
          </Route>
          <Route path="/">
            <div>
              <p>Aim Project Root</p>
              <a href="/setup?role=reviewer">Go to reviewer page</a>
              <br />
              <a href="/setup">Go to advisor page</a>
            </div>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
