import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as yaml from 'js-yaml';

import RecordTable from "../../shared/RecordTable/RecordTable";
import EditableCell from "../../shared/EditableCell/EditableCell";
import NumberCell from "../../shared/NumberCell/NumberCell";
import FileUploadCell from '../../shared/FileUploadCell/FileUploadCell';
import * as api from "../../../modules/api";

import "./edit_agreements.scss";

export default function EditAgreements(props) {
  const [loading, setLoading] = useState(true);
  const [headers, setHeaders] = useState();
  // const [tab, setTab] = useState('advisory');
  const [advisory, setAdvisory] = useState([]);
  const [brokerage, setBrokerage] = useState([]);
  const [commissionable, setCommissionable] = useState([]);
  const [profservices, setProfServices] = useState([]);

  useEffect(() => {
    async function getAgreements() {
      await getModel();
      const accounts = await api.getAccounts();

      setAdvisory(accounts.body.filter(account => !account['Wealth Management Service Type'] || account['Wealth Management Service Type'].toLowerCase() === 'advisory'));
      setBrokerage(accounts.body.filter(account => account['Wealth Management Service Type'] && account['Wealth Management Service Type'].toLowerCase() === 'brokerage'));
      setCommissionable(accounts.body.filter(account => account['Wealth Management Service Type'] && account['Wealth Management Service Type'].toLowerCase() === 'commissionable'));
      setProfServices(accounts.body.filter(account => account['Wealth Management Service Type'] && account['Wealth Management Service Type'].toLowerCase() === 'professional services'));

      setLoading(false);
    }

    async function getModel() {
      // figure out model retrieval and processing
      const results = await fetch('/model.yaml');
      const text = await results.text();
      const model = yaml.load(text);

      // figure out picklists
      const picklists = await api.getPickLists();
      const mappedPicklists = picklists.body.reduce((acc, item) => {
        if (!acc[item.list_name]) {
          acc[item.list_name] = [item.item];
        }
        else {
          acc[item.list_name].push(item.item);
        }

        return acc;
      }, {});

      const incomingHeaders = [];
      for (const [key, value] of Object.entries(model.entities.agreement)) {
        const columns = [];
        for (const [columnKey, columnValue] of Object.entries(value)) {
          let headerMarkup = columnValue.label;

          const column = {
            label: headerMarkup,
            field: columnValue.name,
          };

          if (columnValue.data_type === 'Enumerated List') {
            let options;
            if (typeof columnValue.field === 'string' && mappedPicklists[columnValue.field.replaceAll('.', '/')]) {
              options = mappedPicklists[columnValue.field.replaceAll('.', '/')];
            }
            else {
              options = Array.isArray(columnValue.allowed_values) ? columnValue.allowed_values : [columnValue.allowed_values];
            }
            console.dir(options);
            column.editorType = 'select';
            column.selectValues = options;
          }
          else if (columnValue.data_type === 'Numeric') {
            column.cell = NumberCell;
          }
          else if (columnValue.data_type === 'PDF File') {
            column.cell = FileUploadCell;
          }
          else if (columnValue.data_type === 'Text') {
            column.cell = EditableCell;
          }
          else if (columnValue.data_type === 'Date') {
            column.editorType = 'date';
          }
          else {
            console.error(`Unrecognized data_type: "${columnValue.data_type}"`);
            column.cell = EditableCell;
          }

          columns.push(column)
        }

        incomingHeaders.push({ label: key, columns });
      }

      setHeaders(incomingHeaders);
    }

    getAgreements();
  }, []);

  let bodyMarkup;
  if (!loading) {
    let data = [];
    if (props.tab === 'advisory') {
      data = advisory;
    }
    else if (props.tab === 'brokerage') {
      data = brokerage;
    }
    else if (props.tab === 'commissionable') {
      data = commissionable;
    }
    else if (props.tab === 'profservices') {
      data = profservices;
    }
    else {
      data = [];
    }

    bodyMarkup = (
      <RecordTable isAccount={true} data={data} headers={headers} />
    );
  }
  else {
    bodyMarkup = (
      <div>
        Loading...
      </div>
    );
  }

  return (
    <div id="edit-agreements-container">
      <div id="agreement-selection-tab">
        <Link to={`/projects/${props.activeProject.id}/editagreements/advisory`} className={`agreement-tab clickable ${props.tab === 'advisory' ? 'active-tab' : ''}`}>
          <div>
            <p>Advisory Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/projects/${props.activeProject.id}/editagreements/brokerage`} className={`agreement-tab clickable ${props.tab === 'brokerage' ? 'active-tab' : ''}`}>
          <div>
            <p>Brokerage Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/projects/${props.activeProject.id}/editagreements/commissionable`} className={`agreement-tab clickable ${props.tab === 'commissionable' ? 'active-tab' : ''}`}>
          <div>
            <p>Commissionable Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/projects/${props.activeProject.id}/editagreements/profservices`} className={`agreement-tab clickable ${props.tab === 'profservices' ? 'active-tab' : ''}`}>
          <div>
            <p>Professional Services Agreements</p>
          </div>
        </Link>
      </div>
      <div id="edit-agreements-table-container">
        {bodyMarkup}
        <div id="agreement-right-hand-flyout">
        </div>
      </div>
    </div>
  );
}
