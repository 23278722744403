import { Switch, Route } from "react-router-dom";

import "./header.scss";

export default function Header(props) {
  let headerTitle = 'Welcome';
  console.dir(props.activeProject.name)
  if (props.activeProject && props.activeProject.name) {
    const tokens = props.activeProject.name.split(' - ');
    headerTitle = (
      <>
        <span>{tokens[0]} </span>
        <small className="text-muted"> {tokens[1]}</small>
      </>
    )
  }

  return (
    <div id="content-header">
      <div id="content-header-left">
        <h2 class="content-header-title">{headerTitle}</h2>

        <Switch>
          {/* <Route path={`/projects/support/welcome`}>
            <h6 class="content-header-subtitle">Welcome</h6>
          </Route>
          <Route path={`/projects/support`}>
            <h6 class="content-header-subtitle">Help</h6>
          </Route> */}
          <Route path={`/projects/dataadmin`}>
            <h6 class="content-header-subtitle">Global Data Admin</h6>
          </Route>
          <Route path={`/projects/:id/import`}>
            <h6 class="content-header-subtitle">Import Tools</h6>
          </Route>
          <Route path={`/projects/:id/import/defaults`}>
            <h6 class="content-header-subtitle">Import Tools &gt; Fill Defaults</h6>
          </Route>
          <Route path={`/projects/:id/import/confirm`}>
            <h6 class="content-header-subtitle">Import Tools &gt; Confirm</h6>
          </Route>
          <Route path={`/projects/:id/history`}>
            <h6 class="content-header-subtitle">Upload History</h6>
          </Route>
          <Route path={`/projects/:id/editcontacts`}>
            <h6 class="content-header-subtitle">Edit - Contacts</h6>
          </Route>
          <Route path={`/projects/:id/editagreements/advisory`}>
            <h6 class="content-header-subtitle">Edit - Financial Accounts &gt; Advisory Financial Accounts</h6>
          </Route>
          <Route path={`/projects/:id/editagreements/brokerage`}>
            <h6 class="content-header-subtitle">Edit - Financial Accounts &gt; Brokerage Financial Accounts</h6>
          </Route>
          <Route path={`/projects/:id/editagreements/commissionable`}>
            <h6 class="content-header-subtitle">Edit - Financial Accounts &gt; Commissionable Financial Accounts</h6>
          </Route>
          <Route path={`/projects/:id/editagreements/profservices`}>
            <h6 class="content-header-subtitle">Edit - Financial Accounts &gt; Professional Services Financial Accounts</h6>
          </Route>
          <Route path={`/projects/:id/editagreements`}>
            <h6 class="content-header-subtitle">Edit - Financial Accounts &gt; Advisory Financial Accounts</h6>
          </Route>
          {/* <Route path={`/projects/:id/support/welcome`}>
            <h6 class="content-header-subtitle">Welcome</h6>
          </Route>
          <Route path={`/projects/:id/support`}>
            <h6 class="content-header-subtitle">Help</h6>
          </Route> */}
          <Route path={`/projects/:id`}>
            <h6 class="content-header-subtitle">Project Dashboard</h6>
          </Route>
          <Route path={`/projects`}>
            <h6 class="content-header-subtitle">Home</h6>
          </Route>
        </Switch>
      </div>

      <div id="content-header-right">
      </div>
    </div>
  )
}
