import { useState, useEffect } from "react";

import "./editable_cell.scss";

export default function EditableCell({ value: initialValue, row: { index }, column: { id }, updateMyData }) {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = e => {
    setValue(e.target.value);
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  }

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue]);

  let valid = true;
  if (!value) {
    valid = false;
  }

  let addedClasses;
  if (!valid) {
    addedClasses = 'invalid';
  }

  let icon; //= <i className="fas fa-edit"></i>;
  if (!valid) {
    icon = <i className="fas fa-exclamation-triangle"></i>
  }

  return (
    <div className={`table-text-container ${addedClasses}`}>
      {icon}
      <input className="table-text-input" value={value} onChange={onChange} onBlur={onBlur} />
    </div>
  );
}
