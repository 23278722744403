import "./aim_modal.scss";

export default function AimModal(props) {
  return (
    <div id="aim-modal-container" className={props.show ? 'visible-modal' : 'invisible-modal'}>
      <div id="aim-modal-content">
        {props.children}
      </div>
    </div>
  )
}
