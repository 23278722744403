import * as yaml from 'js-yaml';
import { useState, useEffect } from "react";

import RecordTable from "../../shared/RecordTable/RecordTable";
import EditableCell from "../../shared/EditableCell/EditableCell";
import NumberCell from "../../shared/NumberCell/NumberCell";
import FileUploadCell from '../../shared/FileUploadCell/FileUploadCell';
import * as api from "../../../modules/api";

import "./edit_contacts.scss";

export default function EditContacts(props) {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [headers, setHeaders] = useState();

  useEffect(() => {
    async function getContacts() {
      await getModel();
      const clients = await api.getContacts();
      // console.dir(clients)

      setContacts(clients.body);
      setLoading(false);
    }

    async function getModel() {
      // figure out model retrieval and processing
      const results = await fetch('/model.yaml');
      const text = await results.text();
      const model = yaml.load(text);

      // figure out picklists
      const picklists = await api.getPickLists();
      const mappedPicklists = picklists.body.reduce((acc, item) => {
        if (!acc[item.list_name]) {
          acc[item.list_name] = [item.item];
        }
        else {
          acc[item.list_name].push(item.item);
        }

        return acc;
      }, {});

      const incomingHeaders = [];
      for (const [key, value] of Object.entries(model.entities.client)) {
        const columns = [];
        for (const [columnKey, columnValue] of Object.entries(value)) {
          let headerMarkup = columnValue.label;

          const column = {
            label: headerMarkup,
            field: columnValue.name,
          };

          if (columnValue.data_type === 'Enumerated List') {
            let options;
            if (mappedPicklists[columnValue.field]) {
              options = mappedPicklists[columnValue.field];
            }
            else {
              options = Array.isArray(columnValue.allowed_values) ? columnValue.allowed_values : [columnValue.allowed_values];
            }
            console.dir(options);
            column.editorType = 'select';
            column.selectValues = options;
          }
          else if (columnValue.data_type === 'Numeric') {
            column.cell = NumberCell;
          }
          else if (columnValue.data_type === 'PDF File') {
            column.cell = FileUploadCell;
          }
          else if (columnValue.data_type === 'Text') {
            column.cell = EditableCell;
          }
          else if (columnValue.data_type === 'Date') {
            column.editorType = 'date';
          }
          else {
            console.error(`Unrecognized data_type: "${columnValue.data_type}"`);
            column.cell = EditableCell;
          }

          columns.push(column)
        }

        incomingHeaders.push({ label: key, columns });
      }

      setHeaders(incomingHeaders);
    }

    getContacts();
  }, []);

  let bodyMarkup;
  if (loading) {
    bodyMarkup = (
      <div>
        Loading...
      </div>
    );
  }
  else {
    bodyMarkup = (
      <RecordTable isAccount={false} data={contacts} headers={headers} />
    );
  }

  return (
    <div id="edit-contacts-container">
      {bodyMarkup}
    </div>
  );
}
