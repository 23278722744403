import { useState } from "react";

import FileGutter from "./FileGutter/FileGutter";
import * as api from "../../../modules/api";

import "./file_drop_zone.scss";

/*
  props:

  preLoadedFiles: an array of filename strings to have included
  username: username string- for loading from the api
  onUpload: a function to run once a file has been uploaded
*/
export default function FileDropZone(props) {
  let preLoadedFiles = [];
  if (props.preLoadedFiles) {
    preLoadedFiles = preLoadedFiles.concat(props.preLoadedFiles);
  }

  const [uploadedFileList, setUploadedFileList] = useState(preLoadedFiles);
  const [dragover, setDragover] = useState(false);
  const [drop, setDrop] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [rejected, setRejected] = useState(false);

  let style;
  let message;
  if (rejected) {
    style = 'drop-zone-rejected';

    message = (
      <div id="drop-zone-message">
        <i className="fas fa-times-circle fa-5x"></i>  
        <p>File could not be uploaded</p>
      </div>
    )
  }
  else if (uploaded) {
    style = 'drop-zone-uploaded';

    message = (
      <div id="drop-zone-message">
        <i className="fas fa-check-circle fa-5x"></i>       
        <p>File uploaded successfully</p>
      </div>
    )
  }
  else if (drop) {
    style = 'drop-zone-dropped';

    message = (
      <div id="drop-zone-message">
        <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        <p>Uploading...</p>
      </div>
    );
  }
  else if (dragover) {
    style = 'drop-zone-over';

    message = (
      <div id="drop-zone-message">
        <i className="fas fa-file-upload fa-5x"></i>
        <p>Release to upload file</p>
      </div>
    );
  }
  else {
    style = 'drop-zone-inactive text-secondary';

    message = (
      <div id="drop-zone-message">
        <i className="fas fa-file-upload fa-5x"></i>
        <p>Drag files in here to upload</p>
      </div>
    );
  }

  async function onDropHandler(event) {
    event.preventDefault();

    setDrop(true);
    setUploaded(false);
    setDragover(false);

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (event.dataTransfer.items[i].kind === 'file') {
          var file = event.dataTransfer.items[i].getAsFile();

          try {
            const buffer = await file.arrayBuffer();
            const res = await api.uploadFiles(buffer, file.name);

            if (!res.ok) {
              throw new Error('File upload unsuccessful');
            }

            if (props.onUpload) {
              props.onUpload(res.body);
            }

            setUploadedFileList([ file.name ])
            setUploaded(true);
            setDrop(false);
          }
          catch (error) {
            console.error(error);
            setRejected(true);
          };
        }
      }
    }
    else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < event.dataTransfer.files.length; i++) {
        // gotta do something for this...
        console.log('... file[' + i + '].name = ' + event.dataTransfer.files[i].name);
      }
    }
  }

  function onDragLeaveHandler(event) {
    event.preventDefault();
    if (dragover) {
      setDragover(false);
    }

    if (uploaded) {
      setUploaded(false);
    }

    if (rejected) {
      setRejected(false);
    }
  }

  function onDragOverHandler(event) {
    event.preventDefault();
    if (!dragover) {
      setDragover(true);
    }

    if (uploaded) {
      setUploaded(false);
    }

    if (rejected) {
      setRejected(false);
    }
  }

  return (
    <>
      <div
        id="drop-zone"
        className={style}
        onDrop={onDropHandler}
        onDragLeave={onDragLeaveHandler}
        onDragOver={onDragOverHandler}>
        {message}
      </div>
      <FileGutter fileList={uploadedFileList} />
    </>
  );
}
