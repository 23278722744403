import "./support.scss";

export default function Support() {
  return (
      <>
        <div id="support-page-container">
          <div>
            <div>
              <h3>Importing and Editing Records</h3>
            </div>
            <div class="support-section-row">
              <div class="support-section-column">
                <video controls width="100%">
                  <source src="/videos/animation.mp4" type="video/mp4" />
                </video>
              </div>
              <div class="support-section-column">
                <div className="support-card">
                  <div className="support-card-header">
                    Importing Files
                  </div>
                  <div className="support-card-body">
                    Need step-by-step instructions?
                  </div>
                  <div className="support-card-footer">
                    <a href="/pdfs\AIM Instructions - how to import a file.pdf"><i className="fas fa-file-download"></i> Instructions</a>
                  </div>
                </div>
                <div className="support-card">
                  <div className="support-card-header">
                    Editing Records
                  </div>
                  <div  className="support-card-body">
                    Need step-by-step instructions?
                  </div>
                  <div className="support-card-footer">
                    <a href="/pdfs\AIM Instructions - how to use the table editor.pdf"><i className="fas fa-file-download"></i> Instructions</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
