import { useMemo } from "react";
import { useTable, useFilters, useSortBy } from "react-table";

import "./upload_history_table.scss";

export default function UploadHistoryTable(props) {
  const columns = useMemo(() => [
    {
      Header: 'Uploaded Date',
      accessor: 'captured',
    },
    {
      Header: 'Uploaded By',
      accessor: 'uploader',
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Filename',
      accessor: 'filename',
    },
    {
      Header: '# Rows',
      accessor: 'rows',
    },
    {
      Header: '# Columns',
      accessor: 'columns',
    },
    {
      Header: 'Issues',
      accessor: 'issues',
    },
  ], []);

  // Define a default UI for filtering
  function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
    const count = preFilteredRows.length;

    return (
      <input
        value={filterValue || ''}
        onChange={e => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    );
  }

  const defaultColumn = useMemo(() => ({
    // Let's set up our default Filter UI
    Filter: DefaultColumnFilter,
  }), []);

  const filterTypes = useMemo(() => ({
    // Or, override the default text filter to use
    // "startWith"
    text: (rows, id, filterValue) => {
      return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue !== undefined
        ? String(rowValue)
          .toLowerCase()
          .startsWith(String(filterValue).toLowerCase())
        : true
      })
    },
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable({ columns, data: props.data, defaultColumn, filterTypes }, useFilters, useSortBy);

  return (
    <>
      <table id="upload-history-table" {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className="upload-history-table-header">
                  <span>
                    {column.render('Header')}
                  </span>
                  {/* Add a sort direction indicator */}
                  <span className="sort-controls">
                    {column.isSorted
                    ? column.isSortedDesc
                      ? <i class="fas fa-sort-down"></i>
                      : <i class="fas fa-sort-up"></i>
                    : <i class="fas fa-sort"></i>}
                  </span>
                </th>
              ))}
            </tr>
          ))}

          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="upload-history-table-filter-header">
                  <div className="record-table-header-filter">
                    {column.render('Filter')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <tr
                className={`upload-history-table-row clickable`}
                {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} className="upload-history-table-cell">
                        {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  );
}
