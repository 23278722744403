export let ALL_PATHS = {};

async function init() {
  const paths = await fetch('/paths.json');
  ALL_PATHS = await paths.json();
}

/*
  Core call
*/
async function callApi(url, method, body) {
  try {
    const response = await fetch(url,
    {
      method,
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: body && JSON.stringify(body)
    });
    const jsonResponse = await response.json();
  
    return ({ status: response.status, ok: response.ok, body: jsonResponse });
  }
  catch (fetchError) {
    const message = `API error when performing ${method} ${url}: ${fetchError}`;
    console.error(message);
    throw new Error(message);
  }
}

/*
  Convenience functions for HTTP methods
*/
async function get(path) {
  return await callApi(path, 'GET');
}

async function post(path, body) {
  return await callApi(path, 'POST', body); 
}

async function put(path, body) {
  return await callApi(path, 'PUT', body); 
}

async function patch(path, body) {
  return await callApi(path, 'PATCH', body); 
}

/*
  API calls
*/
export async function editRows(isAccount, rows) {
  return await Promise.all(rows.map((row) => {
    if (isAccount) {
      return updateAccount(row);
    }
    else {
      return updateContact(row);
    }
  }));
}

export async function promoteRows(isAccount, rows) {
  return await Promise.all(rows.map((row) => {
    row.status = 'promoted';
    if (isAccount) {
      return updateAccount(row);
    }
    else {
      return updateContact(row);
    }
  }));
}

export async function demoteRows(isAccount, rows) {
  return await Promise.all(rows.map((row) => {
    row.status = 'demoted';
    if (isAccount) {
      return updateAccount(row);
    }
    else {
      return updateContact(row);
    }
  }));
}

// this endpoint currently doesn't exist in the backend
export async function getProjects() {
  return await get('/api/projects');
}

/*
  "Official" API endpoints
*/
export async function getAccounts() {
  await init();
  return await get(ALL_PATHS.getAccountsPath);
}

export async function updateAccount(data) {
  await init();
  return await put(ALL_PATHS.updateAccountPath, data);
}

export async function changeAccountStatus() {
  await init();
  return await patch(ALL_PATHS.changeAccountStatusPath);
}

export async function getContacts() {
  await init();
  return await get(ALL_PATHS.getContactsPath);
}

export async function updateContact(data) {
  await init();
  return await put(ALL_PATHS.updateContactPath, data);
}

export async function changeContactStatus() {
  await init();
  return await patch(ALL_PATHS.changeContactStatusPath);
}

export async function uploadFiles(fileData, filename) {
  await init();
  try {
    const response = await fetch(`${ALL_PATHS.uploadFilesPath}/${encodeURIComponent(filename)}`,
    {
      method: 'POST',
      mode: 'cors',
      body: fileData
    });
    const jsonResponse = await response.json();
  
    return ({ status: response.status, ok: response.ok, body: jsonResponse });
  }
  catch (fetchError) {
    const message = `API error when performing POST /ui/files: ${fetchError}`;
    console.error(message);
    throw new Error(message);
  }
}

export async function getFileHistory() {
  await init();
  return await get(ALL_PATHS.getFileHistoryPath);
}

export async function sendAccountsToCas(data) {
  await init();
  return await post(ALL_PATHS.sendAccountsToCasPath, data);
}

export async function sendContactsToCas(data) {
  await init();
  return await post(ALL_PATHS.sendContactsToCasPath, data);
}

// export async function pushContactsToCas(data) {
//   await init();
//   return await post(ALL_PATHS.sendContactsToCasPath, data);
// }

// export async function pushAccountsToCas(data) {
//   await init();
//   return await post(ALL_PATHS.sendAccountsToCasPath, data);
// }

export async function getAccountsReport() {
  await init();
  return await get(ALL_PATHS.getAccountsReportPath);
}

export async function getContactsReport() {
  await init();
  return await get(ALL_PATHS.getContactsReportPath);
}

export async function getDashboardValues() {
  await init();
  return await get(ALL_PATHS.getDashboardValuesPath);
}

export async function getPickLists() {
  await init();
  return await get(ALL_PATHS.getPickListsPath);
}

export async function getQaMetrics() {
  await init();
  return await get(ALL_PATHS.getQaMetricsPath);
}

export async function getPaths() {
  await init();
  return ALL_PATHS;
}
